<template>
  <div class="content flatpage">
    <div v-if="page">
      <h1>{{ page.title }}</h1>
      <div v-html="page.body" />
    </div>

    <div
      v-else-if="loading"
      class="d-flex justify-content-center mt-5"
    >
      <b-spinner
        variant="info"
        style="width: 80px; height: 80px;"
      />
    </div>

    <template v-else-if="notFound">
      <div
        class="alert alert-danger"
        role="alert"
      >
        The page you are looking for does not exist.
      </div>
    </template>

    <template v-else-if="error">
      <div
        class="alert alert-danger"
        role="alert"
      >
        An error occurred while trying to load your feed. Please check your
        connection and try again.
      </div>

      <button
        type="button"
        class="btn btn-outline-primary"
        @click="loadPage"
      >
        Try Again
      </button>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Flatpage',
  props: {
    slug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      page: null,
      loading: false,
      error: false,
      notFound: false,
    };
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
  },
  watch: {
    slug() {
      this.loadPage();
    },
  },
  created() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      this.page = null;
      this.loading = true;
      this.error = false;
      this.notFound = false;

      const url = `${process.env.VUE_APP_API_URL}flatpages/${this.slug}/`;

      let response;

      try {
        response = await fetch(
          url,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${this.userData.drf_token}`,
            },
          },
        );
      } catch (e) {} // eslint-disable-line no-empty

      this.loading = false;

      if (response && response.status === 200) {
        const responseJson = await response.json();
        this.page = responseJson;
      } else if (response && response.status === 404) {
        this.notFound = true;
      } else {
        this.error = true;
      }
    },
  },
};
</script>

<style lang="scss">
  .flatpage img {
    max-width: 100% !important;
    height: auto;
  }
</style>
